.consumption-modal-content-container {
    margin-top: 95px;
    margin-bottom: 150px;
}
.consumption-modal-bold-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    
    color: #000000;
}
.consumption-modal-regular-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.consumption-modal-email-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B8DFF;
}


.not-editor-user-blur-container {
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000000000;
}
.not-editor-user-blur-content-container {
    position: absolute;
    top: 70%;
    left: 0;
    width: 100vw;
    height: 30vh;
    z-index: 100000000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-editor-user-text {
    color: #FF0000;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
}


.not-editor-user-footer {
    padding-bottom: 30px;
}

@media only screen and (max-width: 540px) {
    .not-editor-user-text {
      font-size: 12px;
    }
}

@media only screen and (max-width: 380px) {
    .not-editor-user-text {
      font-size: 10px;
    }
}
